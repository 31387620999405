import { Card } from "@nextui-org/react";
import "./PromotionInfo.css";
import { InfoPromotion, KpisPromotionInfo } from "./InfoPromotion";
import PromotionFeatures from "./PromotionFeatures";
import { useEffect, useState } from "react";
import { getAllAssetsByPromo } from "../../Service/AssetsService/AssetsService";
import AedasLoading from "../AedasLoading/AedasLoading";

function PromotionInfoFull(props: any) {
  const { promotion, formik, sellerWindow } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [mainImage, setMainImage] = useState();
  useEffect(() => {
    if (promotion) {
      setIsLoading(true);
      getAllAssetsByPromo(promotion?.id)
        .then((response) => {
          const mainImage = response.data?.principal[0]?.value;
          setMainImage(mainImage);
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [promotion]);

  if (isLoading) return <AedasLoading></AedasLoading>;

  return (
    <Card
      css={{
        width: "100%",
        height: "100%",
        padding: "1px",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Card
        css={{
          width: "30%",
          height: "calc(100vh - 250px)",
          margin: "0px10px",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <InfoPromotion
          mainImage={mainImage}
          promotion={promotion}
          isOutAppointment={true}
        ></InfoPromotion>
      </Card>

      <div
        className="promotion-cards"
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "70%",
          padding: "0px 10px",
          backgroundColor: "none",
          height: "calc(100vh - 250px)",
        }}
      >
        <KpisPromotionInfo
          promotion={promotion}
          isOutAppointment={true}
        ></KpisPromotionInfo>
        <PromotionFeatures
          promotion={promotion}
          formik={formik}
          sellerWindow={sellerWindow}
          isOutAppointment={true}
        />
      </div>
    </Card>
  );
}

export default PromotionInfoFull;
