import { Button } from "@nextui-org/react";

export const DownloadingButton = ({
  isLoadingDocumentation,
  showLanguagesOptions,
  setDocumentationLanguage,
  setShowLanguagesOptions,
  DowloadDocumentation,
  documentacion,
  downloadBase64File,
}: {
  isLoadingDocumentation: boolean;
  showLanguagesOptions: boolean;
  setDocumentationLanguage: any;
  setShowLanguagesOptions: any;
  DowloadDocumentation: any;
  documentacion: any;
  downloadBase64File: any;
}) => {
  return (
    <>
      {isLoadingDocumentation ? (
        <Button disabled>Descargando documentación...</Button>
      ) : !showLanguagesOptions ? (
        <Button
          onClick={() => {
            if (documentacion) {
              downloadBase64File(
                documentacion.appended_documents,
                "documentación_viviendas.pdf",
                "application/pdf"
              );
            } else {
              setShowLanguagesOptions(true);
            }
          }}
        >
          Descargar documentación
        </Button>
      ) : (
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid var(--blue-lis)",
            borderRadius: "5px",
            padding: "5px",
          }}
        >
          <Button
            onClick={() => {
              setDocumentationLanguage("es");
              DowloadDocumentation("es");
            }}
          >
            Español
          </Button>
          <Button
            onClick={() => {
              setDocumentationLanguage("en");
              DowloadDocumentation("en");
            }}
          >
            English
          </Button>
        </div>
      )}
    </>
  );
};
