import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";

export const sendDocumentation = (
  idAppointment: any,
  planosFavoritos: any,
  email: any,
  language: any
) => {
  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      "/Asset/sendDocumentation/" +
      idAppointment +
      "?email=" +
      email +
      "&language=" +
      language,
    planosFavoritos,
    getDefaultConfigHeader()
  );
};

export const getBudget = (idAppointment: any, planosFavoritos: any) => {
  console.log(planosFavoritos);

  return axios
    .post(
      process.env.REACT_APP_API_BACKEND + "/Asset/getBudget/" + idAppointment,
      planosFavoritos,
      { ...getDefaultConfigHeader() }
    )
    .then((res) => res.data);
};
