import { Card, Modal } from "@nextui-org/react";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
// @ts-ignore
import "react-tabs/style/react-tabs.css";
import AedasLoading from "../../Components/AedasLoading/AedasLoading";
import AppointmentClientTabs from "../../Components/Appointment/AppointmentClientTabs";
import ClientInfo from "../../Components/Appointment/ClientInfo";
import StartTheAppointment from "../../Components/Appointment/StartTheAppointment";
import ModalAppointment from "../../Components/ModalAppointment/ModalAppointment";
import ModalNotIntroduces from "../../Components/ModalNotIntroduces/ModalNotIntroduces";
import { getClientByAppointmentId } from "../../Service/AppointmentService/AppointmentService";

import { getUser } from "../../Utils/commonUtils";
import "./Appointment.css";

function Appointment(props: any) {
  const { appointmentPromotionId, setIsAppointmentSelected } = props;
  const user = getUser();
  const [client, setClient] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getClientByAppointmentId(appointmentPromotionId).then((response: any) => {
      setClient(response.data);
      setIsLoading(false);
    });
  }, [appointmentPromotionId]);

  const [openModalStart, setopenModalStart] = useState(false);
  const [openModalNotIntroduced, setModalNotIntroduces] = useState(false);

  const idSeller = user.id;
  const [idClient, setIdClient] = useState("");

  useEffect(() => {
    setIdClient(client?.id);
  }, [client]);

  if (isLoading) {
    return <AedasLoading></AedasLoading>;
  }
  return (
    <>
      <Modal
        scroll
        width="600px"
        open={openModalStart || openModalNotIntroduced}
        preventClose
        onClose={() => {
          setopenModalStart(false);
          setModalNotIntroduces(false);
        }}
        closeButton
      >
        {openModalStart && (
          <ModalAppointment
            id={appointmentPromotionId}
            idClient={idClient}
            idSeller={idSeller}
          ></ModalAppointment>
        )}
        {openModalNotIntroduced && (
          <ModalNotIntroduces
            id_appoiment={appointmentPromotionId}
            where="appointment"
            setModalNotIntroduces={setModalNotIntroduces}
          ></ModalNotIntroduces>
        )}
      </Modal>

      <Card
        css={{
          width: "100%",
          height: "100%",
          padding: "10px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "20%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ClientInfo client={client}></ClientInfo>
          <StartTheAppointment
            setopenModalStart={setopenModalStart}
            setModalNotIntroduces={setModalNotIntroduces}
            setIsAppointmentSelected={setIsAppointmentSelected}
          ></StartTheAppointment>
        </div>
        <div style={{ width: "100%", height: "80%" }}>
          <AppointmentClientTabs
            client={client}
            id={appointmentPromotionId}
            idClient={idClient}
            idSeller={idSeller}
          ></AppointmentClientTabs>
        </div>
      </Card>
    </>
  );
}

export default Appointment;
