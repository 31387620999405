import axios from "axios";
import { getDefaultConfigHeader } from "../../Utils/headerDefaultConfigPetition";
/**
 * Obtiene los planos de una promoción, a saber, dossier comercial y material de calidades.
 * @param idPromo
 * @returns
 */

//TODO: esta llamada hay que eliminarla
export const getDocumentsService = (idPromo: any) => {
  return axios.get(
    process.env.REACT_APP_API_BACKEND + `/Asset/addDossier/${idPromo}`,
    getDefaultConfigHeader()
  );
};

export const getDocumentationNew = (
  idClient: any,
  idPromo: any,
  id_appoiment: any,
  language: string,
  planosFavoritos: any[]
) => {
  const body = {
    id_client: idClient,
    id_promo: idPromo,
    id_appointment: id_appoiment,
    language: language,
    liked_houses: planosFavoritos,
  };
  return axios.post(
    process.env.REACT_APP_API_BACKEND + `/Asset/fileToDownload/`,
    body,
    getDefaultConfigHeader()
  );
};
