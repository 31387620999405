import axios from "axios";
/**
 * Obtiene el documento DIA necesario para las viviendas de las promociones de Andalucía
 */
export const getDIA = (type: string, id_appoiment: string, house: any) => {
  const data = {
    pInmueble: house.pInmueble,
    pTipo: type,
    quoteId: house.quoteId,
    guardarFichero: true,
    oportunidad: house.oporunidad,
  };
  return axios.post(
    process.env.REACT_APP_API_BACKEND +
      `/Asset/getDIADocumentation/` +
      id_appoiment,
    data
  );
};

export const sendDIA = (data: any) => {
  return axios
    .post(
      process.env.REACT_APP_API_BACKEND + "/Asset/sendSignedDIADocument/",
      data
    )
    .then((res) => res.data);
};
