import { FaKey, FaToilet } from "react-icons/fa";
import { FiFlag } from "react-icons/fi";
import moment from "moment";
import { VscLocation } from "react-icons/vsc";
import { BiBed, BiHomeAlt } from "react-icons/bi";
import { IoMdConstruct } from "react-icons/io";
import { Card } from "@nextui-org/react";
import PromotionCardInfo from "../PromotionCardInfo/PromotionCardInfo";
import { MdSell } from "react-icons/md";
import { CiRuler } from "react-icons/ci";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { CSSProperties } from "react";

export const InfoPromotion = (props: any) => {
  const { mainImage, promotion, isOutAppointment } = props;

  const stylesDIVOutOAppointment: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    padding: "10px",
    alignItems: "start",
    width: "100%",
  };

  const stylesDIVInAppointment: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem",
    padding: "10px",
  };

  const stylesCardInAppointment: CSSProperties = {
    height: "6rem",
    width: "6rem",
    objectFit: "cover",
  };

  const stylesCardOutOfAppointment: CSSProperties = {
    height: "auto",
    width: "95%",
    objectFit: "cover",
  };

  return (
    <div>
      <div
        style={
          isOutAppointment ? stylesDIVOutOAppointment : stylesDIVInAppointment
        }
      >
        <Card
          variant="flat"
          style={
            isOutAppointment
              ? stylesCardOutOfAppointment
              : stylesCardInAppointment
          }
        >
          <img
            alt="Imagen principal de la promoción"
            style={{ height: "100%", objectFit: "cover" }}
            src={mainImage}
          ></img>
        </Card>

        <div
          style={{
            fontFamily: "lis-title",
            fontWeight: "bold",
            fontSize: "20px",
            padding: "0.5rem",
          }}
        >
          <div
            style={{
              width: isOutAppointment ? "auto" : "100%",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "flex" }}>
              <VscLocation></VscLocation>
              <div>
                <h1
                  style={{
                    textAlign: "start",
                    fontFamily: "lis-title",
                    fontSize: "15px",
                    fontWeight: "bold",
                    margin: "0px",
                    color: "var(--secondary-color)",
                  }}
                >
                  {promotion?.name}
                </h1>
                <h2
                  style={{
                    textAlign: "start",
                    fontFamily: "lis-body",
                    fontSize: "15px",
                    margin: "0px",
                  }}
                >
                  {promotion?.address}
                </h2>
              </div>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex" }}>
              <FiFlag size={15}></FiFlag>
              <div>
                <h1
                  style={{
                    textAlign: "start",
                    fontFamily: "lis-title",
                    fontSize: "15px",
                    fontWeight: "bold",
                    margin: "0px",
                    color: "var(--secondary-color)",
                  }}
                >
                  Estado
                </h1>
                <h2
                  style={{
                    textAlign: "start",
                    fontFamily: "lis-body",
                    fontSize: "15px",
                    margin: "0px",
                  }}
                >
                  {promotion?.status}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", bottom: 0, height: "auto" }}>
        <div
          className="promotion-dates"
          style={{
            display: "flex",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "33%",
            }}
          >
            <div
              style={{
                borderRadius: "10px",
                width: "2.2rem",
                height: "2.2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "var(--lis-ligthblue)",
              }}
            >
              <IoMdConstruct
                size={32}
                color={"var(--secondary-color)"}
              ></IoMdConstruct>
            </div>
            <div>
              <h1
                style={{
                  textAlign: "start",
                  fontFamily: "lis-title",
                  fontSize: "15px",
                  fontWeight: "bold",
                  margin: "0px",
                  color: "var(--secondary-color)",
                }}
              >
                Inicio obra
              </h1>
              <h2
                style={{
                  textAlign: "center",
                  fontFamily: "lis-body",
                  fontSize: "15px",
                  margin: "0px",
                }}
              >
                {moment(promotion?.fecha_inicio_construccion).format(
                  "DD-MM-YYYY"
                )}
              </h2>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "33%",
            }}
          >
            <div
              style={{
                borderRadius: "10px",
                width: "2.2rem",
                height: "2.2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "var(--lis-ligthblue)",
              }}
            >
              <BiHomeAlt size={32} color={"var(--secondary-color)"}></BiHomeAlt>
            </div>
            <div>
              <h1
                style={{
                  textAlign: "center",
                  fontFamily: "lis-title",
                  fontSize: "15px",
                  fontWeight: "bold",
                  margin: "0px",
                  color: "var(--secondary-color)",
                }}
              >
                Final obra
              </h1>
              <h2
                style={{
                  textAlign: "center",
                  fontFamily: "lis-body",
                  fontSize: "15px",
                  margin: "0px",
                }}
              >
                {moment(promotion?.fecha_fin_construccion).format("DD-MM-YYYY")}
              </h2>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "33%",
            }}
          >
            <div
              style={{
                borderRadius: "10px",
                width: "2.3rem",
                height: "2.2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "var(--lis-ligthblue)",
              }}
            >
              <FaKey size={28} color={"var(--secondary-color)"}></FaKey>
            </div>
            <div>
              <h1
                style={{
                  textAlign: "center",
                  fontFamily: "lis-title",
                  fontSize: "15px",
                  fontWeight: "bold",
                  margin: "0px",
                  color: "var(--secondary-color)",
                }}
              >
                Entrega llaves
              </h1>
              <h2
                style={{
                  textAlign: "center",
                  fontFamily: "lis-body",
                  fontSize: "15px",
                  margin: "0px",
                }}
              >
                {moment(promotion?.fecha_entrega_llaves).format("DD-MM-YYYY")}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const KpisPromotionInfo = ({ promotion, isOutAppointment }: any) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: isOutAppointment ? "15px" : "5px",
        height: "50%",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "hidden",
        padding: "10px",
      }}
    >
      <PromotionCardInfo
        isOutAppointment={isOutAppointment}
        icon={<MdSell size={30} color={"var(--secondary-color)"}></MdSell>}
        title={"Precio desde"}
        value={
          promotion?.priceFrom
            ? `${new Intl.NumberFormat().format(promotion?.priceFrom)}€`
            : ""
        }
      ></PromotionCardInfo>
      <PromotionCardInfo
        isOutAppointment={isOutAppointment}
        icon={<MdSell size={30} color={"var(--secondary-color)"}></MdSell>}
        title={"Precio hasta"}
        value={
          promotion?.priceTo
            ? `${new Intl.NumberFormat().format(promotion.priceTo)}€`
            : ""
        }
      ></PromotionCardInfo>
      <PromotionCardInfo
        isOutAppointment={isOutAppointment}
        icon={<FaToilet size={30} color={"var(--secondary-color)"}></FaToilet>}
        title={"Baños"}
        value={promotion?.bathrooms_average}
      ></PromotionCardInfo>
      <PromotionCardInfo
        isOutAppointment={isOutAppointment}
        icon={<BiBed size={32} color={"var(--secondary-color)"}></BiBed>}
        title={"Habitaciones"}
        value={promotion?.bedrooms_average}
      ></PromotionCardInfo>
      <PromotionCardInfo
        isOutAppointment={isOutAppointment}
        icon={<CiRuler size={32} color={"var(--secondary-color)"}></CiRuler>}
        title={"Metros"}
        value={promotion?.meters?.substring(0, 15)}
      ></PromotionCardInfo>
      <PromotionCardInfo
        isOutAppointment={isOutAppointment}
        icon={
          <HiOutlineBuildingOffice2
            size={32}
            color={"var(--secondary-color)"}
          ></HiOutlineBuildingOffice2>
        }
        title={"Viv. Disp."}
        value={promotion?.viviendas_disponibles}
      ></PromotionCardInfo>
    </div>
  );
};
